import { FC, memo } from "react";
import { handleElHover, handleElLeave } from "../../cursor/Cursor";
import { useInView } from "react-intersection-observer";
import { SKILL_LIST } from "../../../../constants/skill-list";
import "./skill.css";
import "../common/common.css";

export const Skill: FC = memo(() => {
  const [ref, inView]: any = useInView({
    threshold: 0.25,
  });

  const skillClass: string = inView ? "skill_percent" : "close";

  return (
    <div className="about_content_container">
      <h2 className="about_category">Skill</h2>
      <div ref={ref} className="skill_container">
        {SKILL_LIST.map((item, index) => (
          <div key={index} className="skill_wrapper">
            <div className="skill_title">
              <p onMouseEnter={handleElHover} onMouseLeave={handleElLeave}>
                {item.name}
              </p>
            </div>

            <div className="skill_detail_container">
              <div className="skill_detail_wrapper">
                <div className="skill_detail">
                  <div
                    className={skillClass}
                    style={{ width: `${item.percent}%` }}
                  ></div>
                </div>
              </div>

              <p>
                <span className="colored_percent">{item.percent}</span>%
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});

export default Skill;
