import { FC } from "react";
import Transition from "../../features/transition/Transition";
import Footer from "../../features/footer/Footer";
import Works from "../../features/work/Works";
import "./index.css";

const Work: FC = () => {
  return (
    <div className="work_container">
      <Transition className="transition_work" text="What I've done" />
      <Works />
      <Footer className="absolute" />
    </div>
  );
};

export default Work;
