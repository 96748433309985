import React from "react";
import "./error.css";

const PageNotFound: React.FC = () => {
  return (
    <div className="error_container">
      <div className="error_wrapper">
        <h1>404</h1>
        <h2>Page Not Found</h2>
      </div>
    </div>
  );
};

export default PageNotFound;
