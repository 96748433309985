import { FC, memo, useState, useEffect } from "react";
import { CHAT_LIST } from "../../../constants/chat-list";
import "./chat.css";

const Chat: FC = memo(() => {
  const [chatClass, setChatClass] = useState<string>("close");
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    const startChatEffect = () => {
      for (let i = 0; i < CHAT_LIST.length; i++) {
        const setChat = () => {
          setMessage(CHAT_LIST[i]);
          setChatClass("chat_fade_in");
        };

        const closeChat = () => {
          setChatClass("chat_fade_out");
        };

        setTimeout(() => {
          setChat();
          setTimeout(closeChat, 4000);
        }, i * 6000);
      }
    };

    setTimeout(startChatEffect, 3000);
  }, []);

  return <div className={`chat_wrapper ${chatClass}`}>{message}</div>;
});

export default Chat;
