import { FC, memo } from "react";
import "./footer.css";

interface FooterProp {
  className: string;
}

const Footer: FC<FooterProp> = memo(({ className }) => {
  return (
    <footer className={`footer_${className}`}>
      <div className="copyright">
        <small>&copy; 2023 Takumasa Sugiyama</small>
      </div>
    </footer>
  );
});

export default Footer;
