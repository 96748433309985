import { FC, memo } from "react";
import { handleElHover, handleElLeave } from "../../cursor/Cursor";
import car_body from "../../../../images/car/car_body.png";
import tire from "../../../../images/car/tire.png";
import "./profession.css";
import "../common/common.css";

const Profession: FC = memo(() => {
  return (
    <div className="profession_container">
      <div className="profession_wrapper">
        <div>
          <h2
            onMouseEnter={handleElHover}
            onMouseLeave={handleElLeave}
            className="about_category"
          >
            Creative Development
          </h2>

          <p
            onMouseEnter={handleElHover}
            onMouseLeave={handleElLeave}
            className="about_text"
          >
            I make apps, websites, epic photos and videos. I do everything from
            design creation to development.
          </p>
        </div>

        <div className="car_container">
          <div className="car_body">
            <img
              onMouseEnter={handleElHover}
              onMouseLeave={handleElLeave}
              src={car_body}
              alt="car body"
            />
          </div>

          <div className="tire_front">
            <img
              onMouseEnter={handleElHover}
              onMouseLeave={handleElLeave}
              src={tire}
              alt="car tire"
            />
          </div>

          <div className="tire_rear">
            <img
              onMouseEnter={handleElHover}
              onMouseLeave={handleElLeave}
              src={tire}
              alt="car tire"
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default Profession;
