import { FC } from "react";
import Transition from "../../features/transition/Transition";
import Footer from "../../features/footer/Footer";
import "./index.css";
import LeadSocialMedia from "../../features/contact/LeadSocialMedia";

const Contact: FC = () => {
  return (
    <div className="contact_container">
      <Transition className="transition_contact" text="Say Hello!!" />
      <LeadSocialMedia />
      <Footer className="absolute" />
    </div>
  );
};

export default Contact;
