import { FC, memo } from "react";
import { handleElHover, handleElLeave } from "../../cursor/Cursor";
import "./profile.css";
import "../common/common.css";

const Profile: FC = memo(() => {
  return (
    <div className="profile_wrapper">
      <div>
        <h1
          onMouseEnter={handleElHover}
          onMouseLeave={handleElLeave}
          className="about_text"
        >
          Takumasa Sugiyama
        </h1>

        <p
          onMouseEnter={handleElHover}
          onMouseLeave={handleElLeave}
          className="about_text profile_tag"
        >
          #Software engineer #Designer #Photographer #Videographer
        </p>

        <div className="scroll_wrapper">
          <p
            onMouseEnter={handleElHover}
            onMouseLeave={handleElLeave}
            className="scroll_text"
          >
            Scroll
          </p>
          <span className="scrollDown"></span>
        </div>

        <div className="circle_container">
          <div
            onMouseEnter={handleElHover}
            onMouseLeave={handleElLeave}
            className="circle c1"
          ></div>
          <div
            onMouseEnter={handleElHover}
            onMouseLeave={handleElLeave}
            className="circle c2"
          ></div>
        </div>
      </div>
    </div>
  );
});

export default Profile;
