import { FC } from "react";
import Title from "../../features/top/Title";
import Chat from "../../features/top/Chat";
import Transition from "../../features/transition/Transition";
import Footer from "../../features/footer/Footer";
import "./index.css";

const Top: FC = () => {
  return (
    <div className="top_container">
      <Transition className="transition_top" text="Just a second..." />
      <Title />
      <Chat />
      <Footer className="absolute" />
    </div>
  );
};

export default Top;
