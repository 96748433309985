import { FC, memo } from "react";
import "./lead-social-media.css";
import github from "../../../images/contact/github.png";
import linkedin from "../../../images/contact/linkedin.png";

const LeadSocialMedia: FC = memo(() => {
  return (
    <div className="social_media_wrapper">
      <h2>Follow me</h2>

      <div className="social_media_logo_wrapper">
        <a
          href="https://www.linkedin.com/in/takumasa-sugiyama-9420aa238"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="social_media_logo">
            <img src={linkedin} alt="linkedin" />
          </div>
        </a>

        <a
          href="https://github.com/T-Sugiyama-dev"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="social_media_logo">
            <img src={github} alt="github" />
          </div>
        </a>
      </div>
    </div>
  );
});

export default LeadSocialMedia;
