import { FC } from "react";
import Transition from "../../features/transition/Transition";
import Resume from "../../features/about/resume/Resume";
import Skill from "../../features/about/skill/Skill";
import Footer from "../../features/footer/Footer";
import Profile from "../../features/about/profile/Profile";
import Profession from "../../features/about/profession/Profession";
import "./index.css";

const About: FC = () => {
  return (
    <div className="about_container">
      <Transition className="transition_about" text="Who I am" />
      <Profile />
      <Profession />
      <Resume />
      <Skill />
      <Footer className="relative" />
    </div>
  );
};

export default About;
