type SkillContent = {
  name: string;
  percent: number;
};

export const SKILL_LIST: SkillContent[] = [
  { name: "HTML", percent: 85 },
  { name: "CSS", percent: 82 },
  { name: "JavaScript", percent: 85 },
  { name: "TypeScript", percent: 80 },
  { name: "React.js", percent: 82 },
  { name: "Next.js", percent: 76 },
  { name: "Vue.js", percent: 70 },
  { name: "PHP", percent: 77 },
  { name: "Laravel", percent: 70 },
  { name: "Java", percent: 72 },
  { name: "C#", percent: 70 },
  { name: "Spring", percent: 70 },
  { name: "Python", percent: 65 },
  { name: "Go", percent: 60 },
  { name: "Bash Script", percent: 70 },
  { name: "VBA", percent: 60 },
  { name: "Flutter", percent: 52 },
  { name: "AWS", percent: 35 },
  { name: "MySQL", percent: 65 },
  { name: "PostgreSQL", percent: 55 },
  { name: "Oracle DB", percent: 42 },
  { name: "SQL Server", percent: 50 },
  { name: "Figma", percent: 90 },
  { name: "XD", percent: 75 },
  { name: "Photoshop", percent: 78 },
  { name: "Lightroom", percent: 90 },
  { name: "Premiere Pro", percent: 78 },
];